<template>
    <span>
        <i
            v-if="showAsIcon"
            :class="iconClass"
            @click="handleShowHideHelp"
        />
        <transition name="fade">
            <div
                v-if="state.showHelp || !showAsIcon"
                class="form-input-help"
            >
                <span
                    v-if="!showAsIcon"
                    :class="iconClass"
                    class="form-input-help-icon"/>
                <span class="form-input-help-text">
                    <slot></slot>
                </span>
            </div>
        </transition>
    </span>
</template>

<script>
import { computed, defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'form-input-help',
  props: {
    icon: {
      type: [String, Boolean],
      default: false
    },

    showAsIcon: {
      type: Boolean,
      default: true
    }
  },

  setup (props) {

    let state = reactive({
      showHelp: false
    });

    const handleShowHideHelp = () => state.showHelp = !state.showHelp;

    const iconClass = computed(() => (props.icon) ? props.icon : 'icon-info');

    return {
      state, iconClass, handleShowHideHelp
    };

  },
});
</script>

<style lang="scss" scoped>
.form-input-help {
  display: flex;
  align-items: center;

  .form-input-help-icon {
    color: #797c7f;
    font-size: 16px;
    margin: 3px 0;
  }

  .form-input-help-text {
    font-size: 11px;
    font-style: italic;
    color: #8b919b;
  }
}

//Animation classes
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
