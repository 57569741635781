<template>
  <div class="opening" v-loading="loading">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header card-header-with-btn bg-light">
            <div class="card-title">Opening Hours</div>
            <button
              v-if="isAuthorized(permissions.opening_hour_create)"
              class="btn btn-sm btn-primary p-2"
              @click="handleShowHideAction(true)"
            >
              <i class="icon-add"></i>
              Add New
            </button>
          </div>
          <div class="card-body">

            <!--list-->
            <div class="table-responsive">
              <table class="table custom-table">
                <thead>
                <tr>
                  <th>From</th>
                  <th>To</th>
                  <th>Delivery</th>
                  <th>Collection</th>
                  <th class="text-center">
                    <span v-if="isAuthorized(permissionsGroup.openingHours)">Actions</span>
                  </th>
                </tr>
                </thead>
                <tbody>

                <template
                  v-for="(opening, index) in data"
                  :key="`opening-${index}`"
                >
                  <tr>
                    <td class="text-center divider text-capitalize" colspan="4">{{ formatDayName(opening.day) }}</td>
                    <td class="divider">
                      <div v-if="isAuthorized(permissionsGroup.openingHours)">
                        <EditButton
                          v-if="isAuthorized(permissions.opening_hour_update)"
                          @click="handleTimingUpdate(opening)"
                        />

                        <DeleteButton
                          v-if="isAuthorized(permissions.opening_hour_delete)"
                          @click="deleteOpening(opening, false)"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(hour, index) in opening.hours" :key="`hour-${index}`">
                    <td>{{ formatTime(hour.from) }}</td>
                    <td>{{ formatTime(hour.to) }}</td>
                    <td>
                      <span v-html="renderStatus(hour.enabled_delivery ? 'enabled' : 'disabled')" />
                    </td>
                    <td colspan="2">
                      <span v-html="renderStatus(hour.enabled_collection ? 'enabled' : 'disabled')" />
                    </td>
                  </tr>
                  <!--Static display time-->
                  <tr
                    v-if="opening.display_from"
                    class="text-uppercase bg-light"
                  >
                    <td>{{ opening.display_from }}</td>
                    <td>{{ opening.display_to }}</td>
                    <td colspan="3">Static Time</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <opening-time-action
      :show="showActionModal"
      :title="actionTitle"
      :ok-button-text="submitBtnText"
      :isUpdating="isUpdating"
      :updating-item="selectedItem"
      @updated="handleDataUpdated"
      @cancelled="handleShowHideAction(false)"
    />
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { renderStatus } from '@/Mixins/appHelper';
import { formatTime, isAuthorized } from '@/utils/Helper';
import OpeningTimeAction from '@/components/Opening/OpeningTimeAction';
import Toaster from '@/utils/Toaster';
import permissions, { permissionsGroup } from '@/utils/permissions';
import EditButton from '@/components/Util/ListActions/EditButton';
import DeleteButton from '@/components/Util/ListActions/DeleteButton';
import SweetAlert from '@/utils/SweetAlert';

export default {
  name: 'Opening',
  components: { DeleteButton, EditButton, OpeningTimeAction },

  setup () {

    const store = useStore();
    let state = reactive({
      loading: computed(() => store.state.opening.loading),
      data: computed(() => store.getters['opening/openings']),
      selectedItem: {},
      showActionModal: false,
      isUpdating: false,
      actionTitle: 'Add New Timing',
      submitBtnText: 'Add Timing'
    });

    const handleTimingUpdate = (item) => {
      if (!isAuthorized(permissions.opening_hour_update)) return;
      state.selectedItem = item;
      state.isUpdating = true;
      state.actionTitle = 'Update Timing';
      state.submitBtnText = 'Update Timing';
      state.showActionModal = true;
    };

    const handleShowHideAction = (creating = true) => {

      if (creating) {
        if (!isAuthorized(permissions.opening_hour_create)) return;
        state.selectedItem = {};
        state.isUpdating = false;
        state.actionTitle = 'Add New Timing';
        state.submitBtnText = 'Add Timing';
        state.showActionModal = true;
        state.showActionModal = true;
        return;
      }

      state.showActionModal = !state.showActionModal;
    };

    function deleteOpening (item) {
      if (!isAuthorized(permissions.opening_hour_delete)) return;

      SweetAlert.confirmError({
        text: 'This will permanently Delete this opening time',
        preConfirm: async () => {
          await handleDelete(item);
        }
      });
    }

    const handleDelete = async (item) => {
      try {

        let response = await store.dispatch('opening/deleteOpening', item.id);

        Toaster.successAlt({
          message: response.data.message || 'order status successfully updated'
        });

      } catch (e) {

        Toaster.error({
          message: e.message || 'Something Went Wrong'
        });

      }
    };

    const handleDataUpdated = async () => {
      state.showActionModal = !state.showActionModal;
    };

    const getData = async () => {
      const openingHours = store.getters['opening/openings'];

      if (openingHours.length) return;

      await store.dispatch('opening/getOpeningHours');
    };

    const formatDayName = (dayName) => {

      if (!dayName) return;

      const dayNameLower = dayName.toLowerCase();

      switch (dayNameLower) {

        case 'sun':
          return 'Sunday';
        case 'mon':
          return 'Monday';
        case 'tue':
          return 'Tuesday';
        case 'wed':
          return 'Wednesday';
        case 'thu':
          return 'Thursday';
        case 'fri':
          return 'Friday';
        case 'sat':
          return 'Saturday';
      }

    };

    onMounted(async () => {
      await getData();
    });

    return {
      ...toRefs(state),
      formatTime,
      renderStatus,
      handleShowHideAction,
      deleteOpening,
      formatDayName,
      handleTimingUpdate,
      handleDataUpdated,
      isAuthorized,
      permissions,
      permissionsGroup
    };
  }
};
</script>

<style>
.table-badge {
  font-size: 12px;
}

.table .divider {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  background-color: #f8f8f8;
}


.p-dialog .p-dialog-content {
  overflow-y: visible !important;
}


</style>
