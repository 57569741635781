const attributePlaceholder = ':attribute';

export default class ErrorHelper {

  /**
   * Get all error messages
   * @param validator : Object - must match signature with state
   * @param errorMapCb : CallableFunction - call with all errors
   * @param attributesMap : Object
   * @param messagesMap : Object
   */
  static getValidationErrors (validator, errorMapCb, attributesMap = {}, messagesMap = {}) {

    const errorData = {};

    // const allErrors = validator.value.$silentErrors;
    const allErrors = validator.value.$errors;

    allErrors.map(error => {
      errorData[error.$propertyPath] = ErrorHelper.getMessage(error, attributesMap, messagesMap);
    });

    errorMapCb(errorData);
  }

  /**
   *
   * @param error : Object
   * @param attributesMap : Object
   * @param messagesMap : Object
   * @return String
   */
  static getMessage (error, attributesMap, messagesMap) {

    const rule = error.$validator;
    const errorData = extractFromError(error, attributesMap, messagesMap);

    if (errorData.message) return errorData.message;

    switch (rule) {

      case 'required':
        return `${errorData.attribute} is required`;

      case 'minLength':
        return `${errorData.attribute} cannot be less than ${errorData.rule.min}`;

      case 'maxLength':
        return `${errorData.attribute} cannot be more than ${errorData.rule.max}`;

      case 'between':
        return `${errorData.attribute} must be between ${errorData.rule.min} and ${errorData.rule.max}`;

      default:
        return error.$message;
    }
  }

  /**
   * Checks if all field is valid & optionally triggers all errors
   * @param validator : Object
   * @param validateAll : Boolean
   * @return Boolean
   */
  static checkValidity (validator, validateAll = true) {

    // prepare all errors
    if (validateAll) validator.value.$touch();

    return !validator.value.$invalid;
  }

  /**
   * Checks if touched fields has any error
   * @param validator : Object
   * @return Boolean - true if not error otherwise false
   */
  static checkCurrentValidity (validator) {

    return !(validator.value.$errors.length > 0);
  }

  static mapServerError (error, mapperCb) {

    // getting errors & message
    const errors = error.errors;
    const errorMessage = error.message || 'Something went wrong!';

    if (!errors) return;

    /**
     * Extracts first error if available
     * @param error : String|Symbol
     * @return String|False
     */
    const extractError = (error) => error?.[0] || false;

    // execute the map
    mapperCb(errors, extractError);

    // return error message
    return errorMessage;
  }
}

/**
 * Extracts & formats values from validation error
 * @param error : Object
 * @param attributesMap : Object
 * @param messagesMap : Object
 * @return {{messageRaw: *, rule: (*|Object|P|P|{}|Object|Ref<Object>), attribute: (*|string|String), message: (*|string)}}
 */
function extractFromError (error, attributesMap, messagesMap) {

  const attribute = error.$property;
  const attributePath = error.$propertyPath;

  const ruleName = error.$validator;
  const constraint = error.$params;

  // use provided attribute if available
  const mappedAttribute = attributesMap?.[attributePath] || attribute;

  // use provided message if available
  const mappedMessage = messagesMap?.[attributePath];
  let message = '';

  // if custom message available
  if (mappedMessage) {

    // if custom message for this rule is available
    const msgForRule = mappedMessage?.[ruleName];
    message = msgForRule
      ? msgForRule.replaceAll(attributePlaceholder, mappedAttribute)
      : ''
    ;
  }

  return {
    attribute: mappedAttribute,
    message,
    messageRaw: error.$message,
    rule: constraint,
  };
}

