<template>
  <ModalComp
    :show="state.showModal"
    :title-text="title"
    title-icon-class="icon-circular-graph"
    :show-action-button="true"
    :okButtonText="okButtonText"
    :loading="state.dataSubmitting"
    class="opening-item-modal"
    @submit="handleDataSave"
    @hide="fireEvent('cancelled')"
  >
    <transition name="fade">
      <div v-if="state.showModal" class="container">
        <!--dynamic times-->
        <div class="row">
          <div class="form-group col-12">
            <label class="col-form-label">
              For Days:
              <span class="text-danger">*</span>
            </label>
            <TreeSelect
              id="day"
              placeholder="Select a parent category"
              v-model="state.selectedDays"
              :options="state.days"
              :disabled="!state.creating"
              :multiple="true"
            />
            <form-error-msg :error="state.errors.days"/>
          </div>

          <div
            v-for="(times, idx) in state.hours"
            :key="`timeSelection-${idx}`"
            class="input-group hours-group"
          >
            <div class="form-group col-12 mb-0 mt-2 text-primary hours-group-heading">
              <span>
                Timing #{{ idx + 1 }}
                <SwitchComp
                  :checked="times.show"
                  enable-text="Shown"
                  disable-text="Hidden"
                  @change="val => times.show = val"
                />
              </span>
              <span
                class="icon-remove_circle text-dark hours-group-heading-icon"
                @click="handleAddRemoveHours(times.id)"
              ></span>
            </div>

            <div class="form-group col-12 col-lg-6 p-1">
              <label class="col-form-label"> From </label>
              <VueTimepicker
                :format="TIME_SELECTS_FORMAT"
                :minute-interval="1"
                v-model="times.from"
                class="d-block"
                input-width="100%"
                auto-scroll
                hide-clear-button
              />
            </div>

            <div class="form-group col-12 col-lg-6 p-1">
              <label class="col-form-label"> To </label>
              <VueTimepicker
                :format="TIME_SELECTS_FORMAT"
                :minute-interval="1"
                v-model="times.to"
                class="d-block"
                input-width="100%"
                auto-scroll
                hide-clear-button
              />
            </div>
            <div class="form-group col-6">
              <label class="col-form-label">
                Delivery:
                <form-input-help>Disabling will hide the category from users</form-input-help>
              </label>
              <div>
                <SwitchComp
                  :checked="times.deliveryEnabled"
                  @change="(val)=>{
                    if (!val) times.collectionEnabled = true;
                    times.deliveryEnabled = val;
                  }"
                  enable-text="Enabled"
                  disable-text="Disabled"
                />
              </div>
            </div>
            <div class="form-group col-6">
              <label class="col-form-label">
                Collection:
                <form-input-help>Disabling will hide the category from users</form-input-help>
              </label>
              <div>
                <SwitchComp
                  :checked="times.collectionEnabled"
                  @change="(val)=>{
                    if (!val) times.deliveryEnabled = true;
                    times.collectionEnabled = val;
                  }"
                  enable-text="Enabled"
                  disable-text="Disabled"
                />
              </div>
            </div>
          </div>
          <form-error-msg :error="state.errors.time"/>

          <div class="col-12 mt-2 mb-2">
            <button
              class="btn btn-primary btn-block"
              type="button"
              style="width: 100%"
              @click="handleAddRemoveHours()"
            >
              <i class="icon-add-to-list"></i> New
            </button>
          </div>
        </div>

        <!--display times-->
        <div class="row">
          <div
            class="input-group hours-group static-time mt-2"
          >
            <div class="form-group col-12 mb-0 mt-2 text-primary hours-group-heading">
              <span>Static Display Time</span>
            </div>

            <div class="form-group col-12 col-lg-6 p-1">
              <label class="col-form-label">From</label>
              <VueTimepicker
                :format="TIME_SELECTS_FORMAT"
                :minute-interval="10"
                v-model="state.displayStartTime"
                class="d-block"
                input-width="100%"
                auto-scroll
                drop-direction="up"
              />
            </div>

            <div class="form-group col-12 col-lg-6 p-1">
              <label class="col-form-label">To</label>
              <VueTimepicker
                :format="TIME_SELECTS_FORMAT"
                :minute-interval="10"
                v-model="state.displayEndTime"
                class="d-block"
                input-width="100%"
                auto-scroll
                drop-direction="up"
              />
            </div>
          </div>
          <form-error-msg :error="state.errors.time"/>
        </div>
      </div>
    </transition>
  </ModalComp>
</template>

<script>
import 'vue3-timepicker/dist/VueTimepicker.css';
import VueTimepicker from 'vue3-timepicker';
import TreeSelect from '@tkmam1x/vue3-treeselect';
import { defineComponent, reactive, watch, onMounted } from 'vue';
import ModalComp from '@/components/Util/ModalComp';
import SwitchComp from '@/components/Util/SwitchComp';
import FormInputHelp from '@/components/Util/FormInputHelp';
import FormErrorMsg from '@/components/Util/FormErrorMsg';
import { formatTimePickerCompatibleTime } from '@/utils/Helper';

import { useStore } from 'vuex';
import Toaster from '@/utils/Toaster';
import ErrorHelper from '@/utils/ErrorHelper';
import { TIME_SELECTS_FORMAT } from '@/utils/constants';

export default defineComponent({

  name: 'OpeningTimeAction',

  emits: ['updated', 'cancelled'],

  components: { FormErrorMsg, FormInputHelp, SwitchComp, ModalComp, TreeSelect, VueTimepicker },

  props: {

    show: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: 'Add new Timing'
    },

    okButtonText: {
      type: String,
      default: 'Submit'
    },

    isUpdating: {
      type: Boolean,
      default: false
    },

    updatingItem: {
      type: Object,
      default: () => {
      },
    }
  },

  setup (props, { emit }) {

    const store = useStore();

    let state = reactive({

      startTime: null,
      endTime: null,

      displayStartTime: '',
      displayEndTime: '',

      showModal: props.show,
      selectedDays: [],

      errors: {
        days: false,
        time: false,
      },

      dataSubmitting: false,

      date: null,

      days: [
        { id: 'sun', label: 'Sunday' },
        { id: 'mon', label: 'Monday' },
        { id: 'tue', label: 'Tuesday' },
        { id: 'wed', label: 'Wednesday' },
        { id: 'thu', label: 'Thursday' },
        { id: 'fri', label: 'Friday' },
        { id: 'sat', label: 'Saturday' },
      ],

      creating: !props.isUpdating,

      hours: [
        {
          id: Date.now(),
          from: '',
          to: '',
          collectionEnabled: true,
          deliveryEnabled: true,
          show: true,
        }
      ]

    });

    const populateDataCreating = () => {
      state.startTime = null;
      state.endTime = null;
      state.displayStartTime = '';
      state.displayEndTime = '';
      state.selectedDays = [];
      state.date = '';
      state.days = [
        { id: 'sun', label: 'Sunday' },
        { id: 'mon', label: 'Monday' },
        { id: 'tue', label: 'Tuesday' },
        { id: 'wed', label: 'Wednesday' },
        { id: 'thu', label: 'Thursday' },
        { id: 'fri', label: 'Friday' },
        { id: 'sat', label: 'Saturday' },
      ];
      state.creating = true;
      state.hours = [
        {
          id: Date.now(),
          from: '',
          to: '',
          collectionEnabled: true,
          deliveryEnabled: true,
          show: true,
        }
      ];
    };

    const fireEvent = (event) => emit(event);

    const handleAddRemoveHours = (itemId) => {

      if (itemId) {

        if (state.hours.length < 2) {

          Toaster.warning({
            message: 'You cannot Remove Last item'
          });

          return;
        }

        const idx = state.hours.findIndex(itm => itm.id === itemId);
        state.hours.splice(idx, 1);

        return;
      }

      // Create new Timing
      const newTime = {
        id: Date.now(),
        from: '',
        to: '',
        collectionEnabled: true,
        deliveryEnabled: true,
        show: true,
      };

      state.hours.push(newTime);
    };

    const handleDataSave = async () => {

      state.dataSubmitting = true;

      let data = {

        days: state.selectedDays,
        display_from: state.displayStartTime,
        display_to: state.displayEndTime,

        hours: state.hours.map(({ collectionEnabled, deliveryEnabled, from, id, to, show }) => ({
            enabled_collection: !!collectionEnabled,
            enabled_delivery: !!deliveryEnabled,
            from,
            id,
            to,
            show: show ? 1 : 0,
          }
        )),

      };

      await handleCreateUpdateOpening(data);

      state.dataSubmitting = false;
    };

    const handleCreateUpdateOpening = async (data) => {

      try {
        const response = state.creating
          ? await store.dispatch('opening/createOpening', data)
          : await store.dispatch('opening/updateOpening', { data: data, id: state.openingId });

        fireEvent('updated');

        Toaster.successAlt({
          message: response.data.message || `Schedule successfully ${state.creating ? 'Added' : 'Updated'}`
        });
      } catch (e) {

        ErrorHelper.mapServerError(e, (er) => handleServerError(er));

        Toaster.error({
          message: e.message || 'Something went Wrong. please try later'
        });
      }

    };

    function handleServerError (errors) {

      if (typeof errors !== 'object') return;

      let daysErrorMsg = false;
      let timeError = false;

      Object.keys(errors).forEach((item) => {

        if (item.includes('days')) {
          let currentError = errors[item][0];
          daysErrorMsg = currentError.replace(item, 'days');

        }

        if (item.includes('hours')) {

          let currentHourError = errors[item][0];
          timeError = currentHourError.replace(/(.[0-9].)+/g, ' ');

        }

      });

      state.errors.time = timeError;
      state.errors.days = daysErrorMsg;
    }

    const populateUpdateItem = (item) => {

      if (!item) return;

      let day = [item.day];

      state.openingId = item.id;

      let hours = item.hours.map((hours) => ({
        id: hours.id,
        to: formatTimePickerCompatibleTime(hours.to).toString().toLowerCase(),
        from: formatTimePickerCompatibleTime(hours.from).toString().toLowerCase(),
        collectionEnabled: !!hours.enabled_collection,
        deliveryEnabled: !!hours.enabled_delivery,
        show: hours.show
      }));

      state.selectedDays = day;
      state.hours = hours;

      state.displayStartTime = item.display_from ?? '';
      state.displayEndTime = item.display_to ?? '';
    };

    watch(() => props.show, (data) => state.showModal = data);
    watch(() => props.isUpdating, (nv) => state.creating = !nv);
    watch(() => props.updatingItem, (nv) => {
      if (props.isUpdating) {
        populateUpdateItem(nv);
        return;
      }

      populateDataCreating();

    });

    onMounted(() => {

      if (!state.hours) handleAddRemoveHours();

    });

    return {
      handleAddRemoveHours,
      state,
      fireEvent,
      handleDataSave,
      TIME_SELECTS_FORMAT
    };
  }
});

</script>

<style lang="scss">
@import "src/assets/sass/variables";

.hours-group {
  background-color: #9e9e9e3d;
  margin-bottom: 10px;
  border-radius: 10px;

  &.static-time {
    background-color: #eff5fc;
  }

  &-heading {
    display: flex;
    justify-content: space-between;

    &-icon {
      font-size: 25px;
      cursor: pointer;
    }
  }

}

.form-group .has-search{
  position: relative;
}

.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: $primary;
}
.time-picker-overlay {
  //display: none !important;
}
</style>
